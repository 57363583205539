import { Controller } from "@hotwired/stimulus"
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['togglebutton', 'togglebuttoncontainer', 'loading', 'link'];
  //static classes = [ 'selected' ];
  //static values = { status: String }

  //connect() { }
  //disconnect() { }
  //initialize() { }

  toggle(e){
    let frame =document.getElementById('turbo_project_list')
    if (this.togglebuttonTarget.classList.contains('translate-x-0')) {
      this.togglebuttonTarget.classList.remove('translate-x-0')
      this.togglebuttoncontainerTarget.classList.remove('bg-gray-200')
      this.togglebuttonTarget.classList.add('translate-x-5')
      this.togglebuttoncontainerTarget.classList.add('bg-siliblue')
      frame.src='/projects/turbo_project_list?status=wins'
    } else {
      this.togglebuttonTarget.classList.remove('translate-x-5')
      this.togglebuttoncontainerTarget.classList.remove('bg-siliblue')
      this.togglebuttonTarget.classList.add('translate-x-0')
      this.togglebuttoncontainerTarget.classList.add('bg-gray-200')
      frame.src='/projects/turbo_project_list'
    }
  }
}
